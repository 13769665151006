<template>
  <div
    v-loading="loading"
    class="w-full flex flex-1 flex-col"
  >
    <!-- Header -->
    <div class="flex justify-between items-center pb-8 border-b border-gray-200">
      <h3 class="text-base md:text-20 leading-tight font-bold font-heading text-gray-800 truncate pr-16">
        Edit social signature -
        <span class="capitalize">{{ socialSignaturePlatform }}</span>
      </h3>

      <!-- Action buttons -->
      <div class="flex-shrink-0">
        <AppButton
          :to="{ name: routeNames.socialSignatures }"
          variant="danger"
          size="standart-sm"
        >
          Cancel
          <i class="icon-cross text-12 ml-8" />
        </AppButton>
      </div>
    </div>

    <div class="w-full flex pt-16 flex-1 overflow-y-auto">
      <Form
        :validation-schema="socialSignatureUpdateSchema"
        autocomplete="off"
        class="w-full px-4"
        @submit="onSubmitForm"
      >
        <div class="w-full">
          <div class="w-full flex flex-wrap">
            <div class="w-full flex items-center">
              <p class="text-18">Active:</p>
              <AppCheckbox
                id="isActive"
                v-model="socialSignatureModel.isActive"
                name="isActive"
                label="Is signature active?"
                class="mt-8 ml-12"
              />
            </div>

            <div class="w-full mt-16">
              <AppInput
                id="signature"
                v-model="socialSignatureModel.signature"
                type="text"
                name="signature"
                label="Signature"
                placeholder="Some text ..."
                required
                textarea
              />
            </div>
          </div>
        </div>

        <div class="w-full flex justify-center pt-24 pb-24 md:pb-40 md:pt-40">
          <div class="w-full md:w-1/2">
            <AppButton
              :loading="loading"
              variant="primary"
              type="submit"
              class="w-full"
            >
              Submit
            </AppButton>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue'
import { Form } from 'vee-validate'
import { useRouter, useRoute } from 'vue-router'

import { notification } from '@/core/helpers'
import { routeNames } from '@/router'
import { socialSignatureUpdateSchema } from '@/core/schemas'
import { useSocialSignatures } from '@/core/compositions'

import AppButton from '@/components/stateless/AppButton.vue'
import AppInput from '@/components/stateless/AppInput.vue'
import AppCheckbox from '@/components/stateless/AppCheckbox.vue'

export default defineComponent({
  name: 'EditSocialSignatures',

  components: {
    Form,
    AppButton,
    AppInput,
    AppCheckbox
  },

  setup () {
    const router = useRouter()
    const route = useRoute()

    const { socialSignatureModel, getSocialSignature, editSocialSignature } = useSocialSignatures()

    const loading = ref(true)

    const socialSignaturePlatform = computed(() => {
      return route.params?.platform
    })

    onMounted(() => {
      getInitialData()
    })

    const getInitialData = async () => {
      loading.value = true

      try {
        await getSocialSignature(socialSignaturePlatform.value)
      } catch (e) {
        console.error(e)
        notification()
      } finally {
        loading.value = false
      }
    }

    const onSubmitForm = async () => {
      loading.value = true

      try {
        await editSocialSignature(socialSignaturePlatform.value)

        notification({ type: 'success', title: 'Success!', message: 'Social signature successfully updated!' })

        router.push({ name: routeNames.socialSignatures })
      } catch (err) {
        console.log(err)
        notification({ title: 'Social signature updating error!', errorCode: err.error.code })
      } finally {
        loading.value = false
      }
    }

    return {
      routeNames,
      socialSignatureUpdateSchema,
      loading,
      onSubmitForm,
      socialSignatureModel,
      socialSignaturePlatform
    }
  }
})
</script>
